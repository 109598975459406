import React, { useCallback, useEffect, useRef, useState } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { ErrorBoundary, useErrorHandler } from 'react-error-boundary';
import { CookiesProvider, useCookies } from 'react-cookie';
import { Switch, Route, useHistory, useLocation } from 'react-router-dom';
import { debounce, get } from 'lodash';
import {
  ThemeProvider,
  Flex,
  ApiProvider,
  AuthProvider,
  useAuth,
  useApi,
  LogContextProvider,
  useLogContext,
  Box,
  PrimaryButton,
  Text,
  Dialog,
} from '@fivehealth/botero';
import {
  Alert,
  AlertIcon,
  ChakraProvider,
  Flex as FlexCk,
  AlertDescription,
  CloseButton,
  Spinner,
  Divider,
} from '@chakra-ui/react';

import Sidebar from 'components/Sidebar/Sidebar';
import Content from 'components/Content/Content';
import theme from 'theme/theme';
import { AppDataProvider, useAppData } from 'context/AppDataContext';
import ModalProvider, { useModal } from 'context/ModalContext';
import Login from 'views/Login/Login';
import DrawerProvider from 'context/DrawerContext';
import Config from 'Config';
import './App.css';
import {
  checkEnrollDischargeAsyncStatus,
  checkEventsDeleteStatus,
  checkEventsUpdateStatus,
  checkMessageTemplateSendStatus,
  checkPatientImportStatus,
  domainConfigsUtils,
  getHeimdalDiscoveryConfigs,
  getLoginProvider,
} from 'AppUtils';
import LoadingOverlay from 'components/LoadingOverlay';
import useClinicalParametersQuery from 'hooks/useClinicalParametersQuery';
import moment from 'moment';
import { ErrorModalProvider } from 'context/ErrorModalContext';
import { useTranslation } from 'react-i18next';
import { RecoilRoot, useRecoilState } from 'recoil';
import { asyncJobState } from 'states/asyncJobStates';
import asyncJobTypes from 'constants/asyncJobTypes';
import { ChakraTheme } from 'ChakraCustomTheme';
import { useMediaQuery } from 'react-responsive';
import Routes from './Routes';
import ErrorPage from './views/Error/ErrorPage';
import PartyBot from './assets/party-bot-logo.png';

const apiQueryCtx = require.context('./api/queries', true, /.js$/);
const queryMapping = apiQueryCtx.keys().reduce((acc, filename) => {
  const [key] = filename.split('./').pop().split('.js');
  return {
    ...acc,
    [key]: apiQueryCtx(filename).default,
  };
}, {});

const renderErrorPage = ({ resetErrorBoundary, user, errorStackTrace }) => (
  <ErrorPage
    user={user}
    details={errorStackTrace}
    resetErrorBoundary={resetErrorBoundary}
  />
);

const handleError = (error, info) => {
  if (!Config.IS_PRODUCTION()) {
    // TODO: Consider implementing and integrating sentry to store all error logs
    // eslint-disable-next-line
    console.log(`%cERROR: ${error?.message}`, 'color: orange');
    // eslint-disable-next-line
    console.log(
      `%cSTACKTRACE: ${info?.componentStack.toString()}`,
      'color: green'
    );
  }
};

const numOfHours = 3;
const queryDefaultStaleTime = 1000 * 60 * 60 * numOfHours;
const getQueryClient = () =>
  new QueryClient({
    defaultOptions: {
      queries: {
        retry: 0,
        refetchOnmount: false,
        refetchOnReconnect: false,
        staleTime: queryDefaultStaleTime,
      },
    },
  });

let queryClient = getQueryClient();

let poolingJobInterval = null;

const AppContainer = () => {
  const { authState, logout: logoutUser } = useAuth();
  const [cookies] = useCookies([Config.cookie.name]);
  const token = cookies && cookies[Config.cookie.name];
  const authenticated = !!(authState.authenticated && token);

  const history = useHistory();

  const { client } = useApi();

  const sidebarRef = useRef();
  const [sideBarExpand, setSideBarExpand] = useState(false);
  const [errorStackTrace, setErrorStackTrace] = useState(null);
  const { user, setClinic, setClinics, setClinician, clinician } = useAppData();
  const { initAmplitude, setAmplitudeUserProps } = useLogContext();
  const customErrorHandler = useErrorHandler();

  const [asyncJobData, setAsyncJobData] = useRecoilState(asyncJobState);

  const poolingStarted = useRef(false);

  const { t } = useTranslation();
  const isMobile = useMediaQuery({ query: '(max-width: 720px)' });

  // TODO: Move this function into a hook
  const startPooling = () => {
    poolingStarted.current = true;
    const asyncJobUid = get(asyncJobData, 'uid', null);

    if (asyncJobData.status === 'SUCCESS') return;

    let result = null;
    let asyncJob = null;
    let type = '';
    let info = {};

    poolingJobInterval = setInterval(async () => {
      if (!asyncJobUid) return clearInterval(poolingJobInterval);

      if (
        asyncJobData?.type ===
          asyncJobTypes.CleoPatientFormEnrollDischarge.type &&
        asyncJobData?.status !== 'SUCCESS'
      ) {
        result = await checkEnrollDischargeAsyncStatus(
          queryClient,
          client,
          asyncJobUid
        ).then((r) => r);
        asyncJob = get(
          result,
          'cleoPatientFormEnrollDischargeAsync.asyncJob',
          null
        );
        type = asyncJobTypes.CleoPatientFormEnrollDischarge.type;
        info =
          asyncJobTypes.CleoPatientFormEnrollDischarge.infoText[
            asyncJob?.status
          ] ?? {};

        info.info = info.info.replace(
          '{{operation}}',
          asyncJobData.operation === 'DISCHARGE' ? 'Discharging' : 'Enrolling'
        );
        info.title = info.title.replace(
          '{{operation}}',
          asyncJobData.operation === 'DISCHARGE' ? 'Discharging' : 'Enrolling'
        );
      }

      if (
        asyncJobData?.type === asyncJobTypes.CleoPatientEventDelete.type &&
        asyncJobData?.status !== 'SUCCESS'
      ) {
        result = await checkEventsDeleteStatus(
          queryClient,
          client,
          asyncJobUid
        ).then((r) => r);
        asyncJob = get(result, 'cleoPatientEventDeleteAsync.asyncJob', null);
        type = asyncJobTypes.CleoPatientEventDelete.type;
        info =
          asyncJobTypes.CleoPatientEventDelete.infoText[asyncJob?.status] ?? {};
      }

      if (
        asyncJobData?.type === asyncJobTypes.CleoPatientEventsUpdate.type &&
        asyncJobData?.status !== 'SUCCESS'
      ) {
        result = await checkEventsUpdateStatus(
          queryClient,
          client,
          asyncJobUid
        ).then((r) => r);
        asyncJob = get(result, 'cleoPatientEventUpdateAsync.asyncJob', null);
        type = asyncJobTypes.CleoPatientEventsUpdate.type;
        info =
          asyncJobTypes.CleoPatientEventsUpdate.infoText[asyncJob?.status] ??
          {};
      }

      if (
        asyncJobData?.type === asyncJobTypes.CleoPatientImport.type &&
        asyncJobData?.status !== 'SUCCESS'
      ) {
        result = await checkPatientImportStatus(
          queryClient,
          client,
          asyncJobUid
        ).then((r) => r);
        asyncJob = get(result, 'cleoPatientImportAsync.asyncJob', null);
        type = asyncJobTypes.CleoPatientImport.type;
        info = asyncJobTypes.CleoPatientImport.infoText[asyncJob?.status] ?? {};
      }

      if (
        asyncJobData?.type === asyncJobTypes.CleoMessageTemplate.type &&
        asyncJobData?.status !== 'SUCCESS'
      ) {
        result = await checkMessageTemplateSendStatus(
          queryClient,
          client,
          asyncJobUid
        ).then((r) => r);
        asyncJob = get(
          result,
          'cleoMessageTemplateDeliverToPatientAsync.asyncJob',
          null
        );
        type = asyncJobTypes.CleoMessageTemplate.type;
        info =
          asyncJobTypes.CleoMessageTemplate.infoText[asyncJob?.status] ?? {};
      }

      if (!result) {
        setAsyncJobData('');
        return clearInterval(poolingJobInterval);
      }

      setAsyncJobData({
        type,
        ...asyncJobData,
        ...info,
        ...asyncJob,
      });

      if (asyncJob?.status === 'SUCCESS' || asyncJob?.status === 'FAILED') {
        clearInterval(poolingJobInterval);
        if (asyncJob?.status === 'SUCCESS') {
          asyncJobTypes[type]?.onSuccessCallback?.();
        }
        return result;
      }

      return result;
    }, 10000);
  };

  useEffect(() => {
    setTimeout(() => {
      if (
        asyncJobData?.uid &&
        !poolingStarted.current &&
        clinician &&
        asyncJobData?.status !== 'SUCCESS'
      ) {
        startPooling();
      }
    }, 2000);

    if (asyncJobData?.status === 'SUCCESS') {
      poolingStarted.current = false;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asyncJobData, poolingStarted]);

  useEffect(() => {
    // clear query client when changing the session context
    queryClient = getQueryClient();
  }, [authenticated]);

  const {
    queries: {
      useClinic,
      useClinicsWithAccess,
      useClinicAsPatient,
      useCurrentUser,
      usePatientWithSession,
      useCheckSession,
      useHeimdallLogout,
    },
  } = useApi({
    queries: [
      'useClinic',
      'useClinicsWithAccess',
      'useClinicAsPatient',
      'useCurrentUser',
      'usePatientWithSession',
      'useCheckSession',
      'useHeimdallLogout',
    ],
  });

  const { mutateAsync: logoutMutateAsync } = useHeimdallLogout();

  const logout = async () => {
    try {
      logoutUser();
      await logoutMutateAsync({ input: {} });
    } catch (e) {
      customErrorHandler(e);
    }
  };

  const isPatientFacing = get(user, 'isPatientFacing', false);

  const { data: cleoClinicsWithAccess } = useClinicsWithAccess({
    enabled: authenticated && !isPatientFacing,
    staleTime: Infinity,
    onSuccess: ({ data }) => {
      if (data) {
        setClinics(data);
      }
    },
  });

  const { refetch, data: clinic } = useClinic({
    enabled: authenticated && !isPatientFacing,
    staleTime: Infinity,
    onSuccess: ({ data }) => {
      if (data) {
        setClinic(data);
      }
    },
  });
  const { openModal } = useModal();

  const showV3Modal = useCallback(() => {
    openModal(
      <Dialog open onClose={() => {}} style={{ padding: 0 }}>
        <Flex padding="32px" flexDirection="column" alignItems="center">
          <Box as="img" src={PartyBot} height="152px" width="152px" />
          <Text fontWeight="600" fontSize="16px" mb="8px">
            {t('Welcome back')}!
          </Text>

          <Text color="darkestShade" fontSize="16px" mb={4}>
            {t(
              'Your clinic has been upgraded to the latest version of Bot MD Care.'
            )}
          </Text>
          <Box mb={2} width="120%">
            <Divider height={1} />
          </Box>

          <Flex pt="16px" width="100%" justifyContent="flex-end">
            <PrimaryButton
              onClick={() => {
                window.open(
                  `https://clinic.botmd.io/auth/callback?session=${token}`
                );
              }}
            >
              {t('Bring me there')}
            </PrimaryButton>
          </Flex>
        </Flex>
      </Dialog>,
      {
        style: {
          maxWidth: isMobile ? '100%' : '640px',
        },
      }
    );
  }, [openModal, t, isMobile, token]);

  useEffect(() => {
    if (clinic?.isV3) {
      showV3Modal();
    }
  }, [clinic, showV3Modal]);

  useClinicalParametersQuery(authenticated);

  const { data: clinicAsPatient } = useClinicAsPatient({
    enabled: authenticated && isPatientFacing,
    staleTime: Infinity,
  });

  /* eslint-disable react-hooks/rules-of-hooks */
  const {
    data: currentUser,
    refetch: refetchUser,
    isSuccess: currentUserFetched,
  } = isPatientFacing
    ? {}
    : useCurrentUser({
        enabled: authenticated,
        staleTime: Infinity,
        onSuccess: ({ data }) => {
          if (data) {
            setClinician(data);
          }
        },
      });

  useEffect(() => {
    initAmplitude(Config.AMPLITUDE_KEY);
  }, [initAmplitude]);

  const { data: patientInfo } = usePatientWithSession({
    enabled: !!(authenticated && isPatientFacing),
    staleTime: Infinity,
    onSuccess: ({ data }) => {
      if (data === null && authenticated) {
        logout();
      }
    },
  });

  useEffect(() => {
    if (currentUser) {
      setAmplitudeUserProps(currentUser);
    }
  }, [currentUser, setAmplitudeUserProps]);

  const redirectToLogin = () => {
    history.push('/login');
    window.location.reload();
  };

  const { refetch: checkSessionValidity } = useCheckSession({
    enabled:
      authState.authenticated &&
      token &&
      currentUserFetched &&
      !user?.isPatientFacing &&
      currentUser === null,
    variables: {
      uid: token,
    },
    onSuccess: ({ data }) => {
      if (data === null && authenticated && !isPatientFacing) {
        logout();
        redirectToLogin();
      } else {
        const { expiresOn } = data ?? {};
        if (moment(expiresOn).isBefore(moment())) {
          logout();
          redirectToLogin();
        }
      }
    },
  });

  const checkSession = debounce(async () => {
    await checkSessionValidity().then((r) => r);
  }, 2000);

  useEffect(() => {
    const listener = history.listen(() => {
      checkSession();
      return () => listener();
    });
  }, [history, checkSession]);

  const onSetSidebarRef = (ref) => {
    setSideBarExpand(ref.expand);
    sidebarRef.current = ref;
  };

  const fetchData = useCallback(() => {
    if (!clinic) {
      refetch();
    }
    if (!currentUser && refetchUser) {
      refetchUser();
    }
  }, [clinic, currentUser, refetch, refetchUser]);

  useEffect(() => {
    if (authState.authenticated) {
      setTimeout(fetchData(), 500);
    }
  }, [authState.authenticated, fetchData]);

  const onRenderErrPage = ({ resetErrorBoundary }) =>
    renderErrorPage({ resetErrorBoundary, user, errorStackTrace });

  const onErrorCallback = (error, info) => {
    setErrorStackTrace({ error, info });
    handleError(error, info);
  };

  const renderAsyncJobsStatus = () => {
    const { status, info, clinic: domain } = asyncJobData;
    if (clinic?.domain !== domain) {
      return null;
    }

    const alertStatus = () => {
      switch (status) {
        case 'SUCCESS':
          return {
            status: 'success',
            bg: '#D4EFDF',
          };
        case 'FAILED':
          return {
            status: 'error',
          };
        default:
          return {
            status: 'info',
            bg: '#F4F6F8',
            color: '#697481',
          };
      }
    };

    const props = {
      ...alertStatus(),
    };

    return (
      <Alert borderRadius="8px" {...props}>
        {props?.status === 'info' ? (
          <Spinner
            mr={4}
            thickness="4px"
            speed="0.8s"
            emptyColor="blue.200"
            color="blue.400"
            size="md"
          />
        ) : (
          <AlertIcon />
        )}
        <FlexCk flex={1}>
          <AlertDescription>{info}</AlertDescription>
        </FlexCk>
        <FlexCk alignItems="center">
          <Text color="darkestShade" fontSize="12px">
            {moment().format('DD MMM YYYY, h:mm A')}{' '}
          </Text>
          <CloseButton
            color="gray.500"
            ml={3}
            alignSelf="flex-start"
            right={0}
            onClick={() => {
              clearInterval(poolingJobInterval);
              setAsyncJobData('');
            }}
          />
        </FlexCk>
      </Alert>
    );
  };

  return (
    <ErrorModalProvider>
      <DrawerProvider>
        <Flex minHeight="100vh">
          <Box display={['none', 'initial']}>
            {authState.authenticated && (
              <Sidebar
                flexGrow={1}
                sidebarRef={onSetSidebarRef}
                className="botmd-sidebar"
              />
            )}
          </Box>
          <Content
            ml={['auto', sideBarExpand ? 280 : 80]}
            clinic={user?.isPatientFacing ? clinicAsPatient : clinic}
            currentUser={
              user?.isPatientFacing && patientInfo
                ? patientInfo.cleoPatient
                : clinician
            }
            clinics={cleoClinicsWithAccess}
            refetchUser={refetchUser}
          >
            <ErrorBoundary
              fallbackRender={onRenderErrPage}
              onError={onErrorCallback}
            >
              {asyncJobData && renderAsyncJobsStatus()}
              <Routes />
            </ErrorBoundary>
          </Content>
        </Flex>
      </DrawerProvider>
    </ErrorModalProvider>
  );
};

const AppRouter = () => {
  const { login, authState } = useAuth();
  const [cookies] = useCookies([Config.cookie.name]);
  const token = cookies && cookies[Config.cookie.name];

  useEffect(() => {
    if (token && !authState.authenticated) {
      login({ token });
    }
  }, [authState?.authenticated, login, token]);

  return (
    <Switch>
      <Route path="/login/:org?/:clinicname?">
        <Login />
      </Route>
      <Route path="/">
        <ModalProvider>
          <LogContextProvider>
            <AppContainer />
          </LogContextProvider>
        </ModalProvider>
      </Route>
    </Switch>
  );
};

const App = () => {
  const [domainConfigs, setDomainConfigs] = useState();
  const [errorStackTrace, setErrorStackTrace] = useState(null);
  const { resetUser } = useAppData();

  const { t } = useTranslation();

  const { href: host } = window.location;

  const getHeimdalDiscovery = useCallback(async () => {
    const configs = domainConfigsUtils.getDomainConfigs();
    if (!configs) {
      const heimdallDiscovery = await getHeimdalDiscoveryConfigs(host);
      domainConfigsUtils.addDomainConfigs({
        ...heimdallDiscovery,
        host,
      });
      setDomainConfigs(heimdallDiscovery);
    } else {
      setDomainConfigs(configs);
    }
  }, [host]);

  const history = useHistory();
  const { search } = useLocation();

  /* eslint-disable-next-line */
  const [_, setCookie, removeCookie] = useCookies([Config.cookie.name]);

  // TODO: Set domain/clinic via route param on login page
  const applicationInput = JSON.stringify({
    domain: 'carehub.botmd.io',
  });

  const redirectUrl = `${window.location.origin}${Config.REDIRECT_PATH}`;

  const onLoginCallback = (token) => {
    const skipPermanentSessionRequest = new URLSearchParams(
      document.location.search
    ).get('skiptemp');
    if (
      token &&
      !/null/i.test(token) &&
      skipPermanentSessionRequest === 'true'
    ) {
      const path = new URLSearchParams(search).get('path') || '/';
      setCookie(Config.cookie.name, token, { path: '/' });
      history.push(path);
    }
  };

  const onLogOutCallback = () => {
    removeCookie(Config.cookie.name, { path: '/' });
    removeCookie(Config.patientCookie.name, { path: '/' });
    resetUser();
  };

  useEffect(() => {
    getHeimdalDiscovery();
  }, [getHeimdalDiscovery]);

  if (!domainConfigs) {
    return <LoadingOverlay loadingText={`${t('Loading Dashboard')}...`} />;
  }

  const loginUrl = `${
    domainConfigs?.login?.url
  }?uid=${getLoginProvider()}&redirectTo=${redirectUrl}&applicationInput=${applicationInput}&gql_uri=${
    domainConfigs?.hippocrates?.gql_endpoint
  }`;

  const onRenderErrPage = ({ resetErrorBoundary }) =>
    renderErrorPage({ resetErrorBoundary, errorStackTrace });

  const onErrorCallback = (error, info) => {
    setErrorStackTrace({ error, info });
    handleError(error, info);
  };

  return (
    <AuthProvider
      loginUrl={loginUrl}
      redirectPath={Config.REDIRECT_PATH}
      onLogin={onLoginCallback}
      onLogout={onLogOutCallback}
    >
      <CookiesProvider>
        <AppDataProvider>
          <ApiProvider
            queryMapping={queryMapping}
            endpoint={domainConfigs?.hippocrates?.gql_endpoint}
          >
            <QueryClientProvider client={queryClient}>
              <ThemeProvider theme={theme}>
                <ErrorBoundary
                  fallbackRender={onRenderErrPage}
                  onError={onErrorCallback}
                >
                  <ChakraProvider theme={ChakraTheme}>
                    <RecoilRoot>
                      <AppRouter />
                    </RecoilRoot>
                  </ChakraProvider>
                </ErrorBoundary>
                {/* React Query Devtools for localhost only */}
                {window.location.href.includes('localhost') && (
                  <ReactQueryDevtools initialIsOpen={false} />
                )}
              </ThemeProvider>
            </QueryClientProvider>
          </ApiProvider>
        </AppDataProvider>
      </CookiesProvider>
    </AuthProvider>
  );
};

export default App;
